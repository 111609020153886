import styled, { css } from 'styled-components';

export const StyledBenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0px 25px 0px;
  
  @media(min-width: 768px) {
    flex-direction: row;
    margin: 25px 0px 25px 0px;
    align-items: center;
  }
  
  @media(min-width: 992px) {
    margin: 100px 0px 77px 0px;
    align-items: unset;
  }

  h3 {
    font-size: 21px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin-bottom: 20px !important;
    line-height: 42px !important;

    @media(min-width: 768px) {
      width: 100%;
      font-size: 27px;
      text-align: left;
    }
    
    @media(min-width: 992px) {
      font-size: 38px;
      margin-bottom: 50px !important;
    }

  }

  a, button {
    width: 100%;
    margin-top: 30px;
    
    @media(min-width: 768px) {
      justify-content: left;
    }

    @media(min-width: 992px) {
      margin-top: 50px;
    }
  }
`;

export const StyledBenefitsTextWrapper = styled.div`
  width: 100%;
  padding: 0px;

  @media(min-width: 768px) {
    width: 52%;
    padding: 0px 20px 0px 0px;
  }
`;

export const StyledBenefitsImageWrapper = styled.div`
  display: ${props => props.isMobile ? "flex" : "none"};
  width: 100%;
  justify-content: center;
  
  @media(min-width: 768px) {
    display: ${props => props.isMobile ? "none" : "flex"};
    width: 48%;
  }

  @media(min-width: 992px) {
    width: ${props => props.isJetCardPage ? "40%" : "48%"};
  }

  img {
    width: 100%;
    max-width: 420px;
    height: 100%;
    
    @media(min-width: 768px) {
      max-width: none;
      height: 80%;
    }

    @media(min-width: 992px) {
      height: fit-content;
    }
    
    ${props => 
      props.isLogo &&
        css`
          max-width: 340px;
          max-height: 340px;
          padding: 20px;
          background: linear-gradient(206.57deg, #F30E2B 0%, #9E213C 33.8%, #48344E 100%);

          @media(min-width: 992px) {
            margin-top: 30px;
            width: 340px;
            height: 340px;
            margin-bottom: 50px;
          }
        `
      }
  }
`;

// export const StyledProductBenefitsSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex-wrap: nowrap;
//   width: 100%;
//   align-items: center;
//   z-index: 1000;

//   @media(min-width: 992px) {
//     flex-wrap: wrap;
//     min-height: 500px;
//     max-height: 750px;
//     margin-top: 100px;
//   }

//   img#product-benefits-section-image {
//     order: 2;

//     margin-bottom: 0;
//     max-width: 100%;

//     @media(min-width: 992px) {
//       order: 3;
//     }

//     @media(min-width: 1200px) {
//       width: 500px;
//     }

//     ${props => 
//         props.isLogo &&
//           css`
//             max-width: 340px;
//             max-height: 340px;
//             padding: 20px;
//             background: linear-gradient(206.57deg, #F30E2B 0%, #9E213C 33.8%, #48344E 100%);

//             @media(min-width: 992px) {
//               margin-top: 30px;
//               width: 340px;
//               height: 340px;
//               margin-bottom: 50px;
//             }
          
//           `
//       }
//   }

//   h3 {
//     order: 1;
//     font-size: 21px;
//     font-weight: 500;
//     width: 100%;
//     text-align: center;
//     margin-bottom: 20px !important;
//     line-height: 42px !important;

//     @media(min-width: 992px) {
//       width: 100%;
//       font-size: 38px;
//       text-align: left;
//       margin-bottom: 0;
//       margin-right: auto !important;
//     }
//   }

//   a, button {
//     order: 4;
//     margin-top: 30px;

//     @media(min-width: 992px) {
//       order: 2;
//       align-self: flex-start;
//     }
//   }

// `
export const StyledTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  max-width: 306px;
  height: 121px;
  padding: 20px;
  background: white;
  box-shadow: 0 0 12px 0 rgba(72,79,93,0.17);
  border-radius: 1px;
  text-align: center;
  display: none;

  @media(min-width: 992px) {
    display: flex
  }

  &:first-of-type {
    order: 1;
    margin-right: auto;
    margin-top: 75px;
    margin-bottom: 20px;
  }

  &:nth-of-type(2) {
    order: 2;
    margin-right: auto;
  }

  &:nth-of-type(3) {
    order: 4;
    margin-left: auto;
    margin-top: 75px;
    margin-bottom: 20px;
  }

  &:nth-of-type(4) {
    order: 5;
    margin-left: auto;
  }

  p {
    margin: 0;
    color: #3C3C3B;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;

    @media(min-width: 1200px) {
      font-size: 18px;
    }
  }

`

export const StyledList = styled.ul`
  margin-top: 50px;
  padding: 0;
  width: 100%;
  list-style: none;
  order: 3;
  margin-right: auto;

  &:first-of-type {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-top: 20px;

    @media(min-width: 640px) {
      margin-top: 30px;
    }
  }

  @media(min-width: 992px) {
    order: 2;
    // width: 50%;
    margin-right: auto !important;

    /* & > .not-featured-list-item {
      margin-top: 50px !important;
    }

    & > .not-featured-list-item ~ .not-featured-list-item {
      margin-top: 0 !important;
    } */
   }
`

export const StyledListItem = styled.li`
  display: flex;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 21px;
  color: #3C3C3B;
  margin-bottom: 20px;
  align-items: center;

  display: ${props => {
    if (props.featuredItem === false && props.shouldShowAllItem === false) {
      return "none"
    } else {
      return "flex"
    }
  }};

  @media(min-width: 640px) {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  span {
    display: block;
    height: 100%;
    margin-right: 10px;
  }

  img {
    @media(min-width: 640px) {
      margin-right: 20px;

      width: initial;
    }
  }



  &:last-of-type {
    margin-bottom: 0;
  }
`